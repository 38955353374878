import { VertiNodeLocale } from "./VertiNodeLocale.js";
import { Locale } from "./Locale.js";

export const SharedLocale = {
	...Locale,
	...VertiNodeLocale,
} as const;

export type SharedLocale = keyof typeof SharedLocale;

export type SharedLocalizedString = Record<SharedLocale, string>;

export type SharedLocalizedStringArray = Record<SharedLocale, string[]>;

export type SharedLocalizedBoolean = Record<SharedLocale, boolean>;

export type SharedLocalizedID = Record<SharedLocale, string>;

export type RelatedSharedLocaleID = Partial<SharedLocalizedID>;
