import type { SharedLocale } from "../../core/schema/SharedLocale.js";
import type { SharedTenantId } from "../../core/schema/SharedTenant.js";
import type { TenantizedString } from "../../core/schema/Tenant.js";
import type { VertiNodeTenantizedString } from "../../core/schema/VertiNodeTenant.js";

export const sharedGlobalSettingsContextKey = "sharedGlobalSettings";

export interface SharedGlobalSettings {
	VERSION: string;
	TENANT_ID: SharedTenantId;
	TENANT_LOCALE: SharedLocale;
	VERTIFLEX_WEB_URL: TenantizedString;
	VERTINODE_WEB_URL: VertiNodeTenantizedString;
	ENV: string;
	ENV_DOMAIN: string;
	ROBOTS: "index" | "noindex";
	STORAGE_URL: string;
	UPLOAD_URL: string;
	PUBLIC_GRAPHQL_ENDPOINT: string;
}
