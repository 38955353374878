<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import Menu from "./Menu.svelte";

	export let pageSlug: string;
	export let menu: MenuItem[];
</script>

<nav class="hidden w-fit lg:block">
	<Menu {pageSlug} {menu} />
</nav>
