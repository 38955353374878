<script lang="ts" generics="TenantId extends string, Locale extends string">
	import arrowDownLong from "../../../core/assets/icons/arrow-down.svg?raw";
	import Button from "./Button.svelte";
	import Link from "./Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let tenants: { id: TenantId; locale: Locale }[];
	export let webUrl: Record<NoInfer<TenantId>, string>;
	export let pageSlug: Partial<Record<NoInfer<Locale>, string>>;
	export let localeShortHandMap: Record<NoInfer<Locale>, string>;
	export let currentLocale: NoInfer<Locale>;

	let isLanguageListOpen = false;

	function toggleLanguageList(): void {
		isLanguageListOpen = !isLanguageListOpen;
	}
</script>

<div class="group relative">
	<Button
		variant="transparent"
		class="hover:text-primary lg:text-3xs !gap-0.5 text-xs font-medium uppercase tracking-[0.125rem] transition-all duration-300 ease-in-out lg:rounded-lg lg:px-4 lg:py-2 group-hover:lg:bg-white"
		iconClass="w-4 group-hover:lg:rotate-180 duration-300 transition-transform ease-in-out"
		icon={arrowDownLong}
		on:click={toggleLanguageList}
	>
		{nbspify(localeShortHandMap[currentLocale])}
	</Button>
	<div
		class="absolute left-1/2 top-[calc(100%+.25rem)] w-[calc(100%+1rem)] -translate-x-1/2 rounded-lg bg-white shadow-sm transition-opacity duration-300 ease-in-out lg:after:absolute lg:after:-top-1 lg:after:left-0 lg:after:right-0 lg:after:h-1 lg:after:w-full lg:after:content-[''] lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 {isLanguageListOpen
			? 'pointer-events-auto opacity-100 lg:pointer-events-none lg:opacity-0'
			: 'pointer-events-none opacity-0 lg:pointer-events-none lg:opacity-0'}"
	>
		{#each tenants as tenant}
			{#if tenant.locale !== currentLocale}
				<Link
					class="lg:text-3xs hover:text-primary !block w-full rounded-lg p-2 text-center text-xs font-semibold uppercase tracking-[0.125rem] transition-colors duration-300 ease-in-out hover:bg-gray-100 lg:font-normal"
					asButton
					variant="transparent"
					href="{webUrl[tenant.id]}/{pageSlug[tenant.locale] ?? ''}"
				>
					{nbspify(localeShortHandMap[tenant.locale])}
				</Link>
			{/if}
		{/each}
	</div>
</div>
