import type { Locale } from "../../../core/schema/Locale.js";
import type { TenantId } from "../../../core/schema/Tenant.js";
import type { SharedGlobalSettings } from "../../shared/SharedGlobalSettings.js";

export const globalSettingsContextKey = "globalSettings";

export interface GlobalSettings extends Omit<SharedGlobalSettings, "TENANT_ID" | "TENANT_LOCALE"> {
	TENANT_ID: TenantId;
	TENANT_LOCALE: Locale;
	COOKIES: string;
	GOOGLE_ANALYTICS: string;
}
