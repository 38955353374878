export enum VertiNodeLocale {
	cs = "cs",
	en = "en",
}

export type VertiNodeLocalizedString = Record<VertiNodeLocale, string>;

export type VertiNodeLocalizedStringArray = Record<VertiNodeLocale, string[]>;

export type VertiNodeLocalizedBoolean = Record<VertiNodeLocale, boolean>;

export type VertiNodeLocalizedID = Record<VertiNodeLocale, string>;

export type VertiNodeRelatedLocaleID = Partial<VertiNodeLocalizedID>;
