<script lang="ts">
	import { createPromiseStore } from "../../../../core/utils/createPromiseStore.js";
	import type { ContactFormInput } from "../../../../core/schema/ContactForm.js";
	import sendContactForm from "../../api/mutations/sendContactForm.graphql";
	import LabelledInput from "../Form/LabelledInput.svelte";
	import GdprCheckbox from "../Form/GdprCheckbox.svelte";
	import Button from "../Button.svelte";
	import Modal from "../Modal.svelte";
	import Form from "../Form/Form.svelte";
	import Message from "../Message.svelte";
	import { mutation } from "../../api/graphql.js";
	import translations from "./ContactForm.translations.json";
	import arrowRightLong from "../../../../core/assets/icons/arrow-right-long.svg?raw";
	import { ContactFormType } from "../../../../core/schema/ContactFormType.js";
	import type { VertiType } from "../../../../core/schema/VertiType.js";
	import { getSharedTranslate } from "../../utils/getSharedTranslate.js";
	import { getSharedGlobalSettings } from "../../getSharedGlobalSettings.js";

	export let vertiType: VertiType;
	export let id: string | undefined = undefined;
	const translate = getSharedTranslate(translations);

	const { TENANT_ID, PUBLIC_GRAPHQL_ENDPOINT } = getSharedGlobalSettings();

	const send = createPromiseStore(async (input: ContactFormInput) => {
		await mutation<boolean>(PUBLIC_GRAPHQL_ENDPOINT, sendContactForm, { input });
	});

	let showSuccessModal = false;
	async function submit(event: SubmitEvent): Promise<void> {
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		const input: ContactFormInput = {
			vertiType,
			email: (formData.get("email") as string).trim(),
			gdprApprovedAt: formData.get("gdprApprovedAt") as string,
			message: (formData.get("message") as string).trim(),
			name: (formData.get("name") as string).trim(),
			pageUrl: window.location.href,
			phoneNumber: (formData.get("phoneNumber") as string).trim(),
			tenantId: TENANT_ID,
			type: ContactFormType.Contact,
		};
		await send.call(input);
		form.reset();
		showSuccessModal = true;
	}

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<Modal show={showSuccessModal}>
	<p class="h2" slot="title">{translate("thanks")}</p>
	<div class="flex flex-col items-center gap-2">
		<p>{translate("willBeInTouch")}</p>

		<Button
			on:click={() => {
				showSuccessModal = false;
			}}
		>
			{translate("understand")}
		</Button>
	</div>
</Modal>

<Form on:submit={submit} {id}>
	<div class="grid w-full items-end gap-y-3">
		<LabelledInput id="name" required autocomplete="given-name">{translate("name")}</LabelledInput>
		<LabelledInput id="phoneNumber" required type="tel" autocomplete="tel" inputProps={{ pattern: "[+]?[\\d ]+" }}>
			{translate("phone")}
		</LabelledInput>
		<LabelledInput id="email" required type="email" autocomplete="email">{translate("email")}</LabelledInput>
	</div>
	<div class="relative mt-4 w-full">
		<textarea
			id="message"
			name="message"
			class="input-with-label peer h-24 w-full rounded-lg border border-gray-200 px-3 !pb-4 !pt-[1.25rem] text-xs focus:font-semibold focus:outline-none lg:px-4"
			placeholder=" "
			required
			minlength="10"
		></textarea>
		<label class="label textarea-label" for="message">{translate("yourMessage")}<span>&nbsp;*</span></label>
	</div>

	<GdprCheckbox class=" mb-3 justify-center text-white lg:justify-start" />

	<p class="text-2xs mt-2 text-right text-white max-lg:mr-2 lg:text-left">*{translate("compulsoryInfo")}</p>

	<Button
		class="mx-[initial]"
		icon={arrowRightLong}
		type="submit"
		disabled={$send.status === "loading"}
		variant="secondary"
	>
		{#if $send.status === "loading"}
			{translate("submitting")}…
		{:else}
			{translate("submit")}
		{/if}
	</Button>

	{#if $send.status === "error"}
		<Message variant="error" class="mt-4">{translate("somethingWrong")}</Message>
	{/if}
</Form>
