<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import Icon from "../Icon.svelte";
	import StaticPicture from "../Picture/StaticPicture.svelte";
	import { appName } from "../../../../core/schema/names/appName.js";
	import logo from "../../../../core/assets/logo-vertiflex-black.svg";
	import close from "../../../../core/assets/icons/close.svg?raw";
	import Menu from "./Menu.svelte";
	import translations from "./MobileMenu.translations.json";
	import { getSharedTranslate } from "../../utils/getSharedTranslate.js";

	export let pageSlug: string;
	export let isOpen = false;
	export let menu: MenuItem[];

	function onClose(): void {
		isOpen = false;
	}

	function onClick({ detail }: CustomEvent<MenuItem>): void {
		if (detail.id === "kontakt") {
			isOpen = false;
		}
	}
	const translate = getSharedTranslate(translations);
</script>

<nav class="block {isOpen && 'fixed left-0 top-0 z-50 m-0 flex h-full w-[16.0625rem] flex-col bg-gray-300 py-3'}">
	<div class="flex items-center justify-between px-4 pt-4">
		<a href="/" class="w-[4.5rem]">
			<StaticPicture
				alt="Logo {appName}"
				image={logo}
				class="w-[7rem] xl:hidden"
				loading="eager"
				width={142}
				height={64}
			/>
		</a>
		<button class="mt-1" on:click={onClose}>
			<span
				class="text-2xs hover:text-primary flex items-center gap-1 font-semibold leading-5 transition-colors duration-300 ease-in-out"
			>
				{translate("close")}
				<Icon icon={close} class="h-2 w-2" />
			</span>
		</button>
	</div>

	<Menu {pageSlug} {menu} on:click={onClick} />
</nav>
