<script lang="ts">
	export let googleAnalytics: string;

	/* eslint-disable */
</script>

<svelte:head>
	{@html `<script async src="https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}"></script>`}
	{@html `
	<script>
		window.dataLayer ||= [];
		function gtag(){window.dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${googleAnalytics}');
	</script>
	`}
</svelte:head>
