<script lang="ts">
	import cross from "../../../core/assets/icons/cross.svg?raw";
	import Icon from "./Icon.svelte";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import translations from "./Drawer.translations.json";
	import { getSharedTranslate } from "../utils/getSharedTranslate.js";

	export let isOpen = false;
	export let position: "left" | "right";
	export let onClose: () => boolean | Promise<boolean> = () => true;
	export let dontShowCloseButton = false;

	const translate = getSharedTranslate(translations);

	let drawerClass = "";
	export { drawerClass as class };

	$: {
		if (isOpen && !$scrollLockStore) {
			scrollLockStore.set(isOpen);
		}
	}
	$: {
		if (!isOpen) {
			scrollLockStore.set(isOpen);
		}
	}

	async function close(): Promise<void> {
		const shouldClose = await onClose();

		if (shouldClose) {
			isOpen = false;
		}
	}
</script>

{#if isOpen}
	<div class="fixed inset-0 z-50 flex" class:flex-row-reverse={position === "left"}>
		<button
			type="button"
			tabindex="-1"
			class="bg-gray/75 grid h-full w-full flex-1 cursor-default place-items-center backdrop-blur-md"
			on:click|self={close}
		></button>
		<div class="relative overflow-y-auto bg-gray-300 {drawerClass}">
			<div class="px-[.8rem] py-12 min-[450px]:p-14">
				<slot />
			</div>
			{#if !dontShowCloseButton}
				<button type="button" on:click={close} class="absolute right-6 top-6 flex items-center gap-1 font-semibold">
					{translate("close")}
					<Icon icon={cross} class="h-4 w-4" />
				</button>
			{/if}
		</div>
	</div>
{/if}
