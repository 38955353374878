<script lang="ts">
	import translations from "./Address.translations.json";
	import Link from "./Link.svelte";
	import { getSharedTranslate } from "../utils/getSharedTranslate.js";

	const translate = getSharedTranslate(translations);

	let addressClass = "";
	export { addressClass as class };
</script>

<Link
	class="group text-white decoration-transparent hover:decoration-white {addressClass}"
	href={translate("headquartersGoogleMapsLink")}
	target="_blank"
	rel="noopener noreferrer"
>
	<table>
		<tr>
			<td class="whitespace-nowrap font-semibold">{translate("headquartersCompanyName")}</td>
		</tr>
		<tr>
			<td class="whitespace-nowrap">{translate("headquartersStreet")}</td>
		</tr>
		<tr>
			<td class="whitespace-nowrap">{translate("headquartersCity")}</td>
		</tr>
		<tr>
			<td>{translate("headquartersState")}</td>
		</tr>
	</table>
</Link>
