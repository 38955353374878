import { getSharedGlobalSettings } from "../getSharedGlobalSettings.js";
import type { SharedLocale, SharedLocalizedString } from "../../../core/schema/SharedLocale.js";
import { nbspify } from "../../../core/utils/nbspify.js";

function translate<Translations extends Record<string, SharedLocalizedString>, Key extends keyof Translations>(
	translations: Translations,
	key: Key,
	locale: SharedLocale,
	variables: Record<string, string | number> = {},
	disableNbspify: boolean,
): Translations[Key][SharedLocale] {
	const translation = translations[key]?.[locale];
	let finalTranslation = String(translation ?? key);
	for (const [variable, value] of Object.entries(variables)) {
		finalTranslation = finalTranslation.replaceAll(`{{${variable}}}`, String(value));
	}
	const text = finalTranslation as Translations[Key][SharedLocale];
	if (disableNbspify) {
		return text;
	}
	return nbspify(text);
}

export function getSharedTranslate<Translations extends Record<string, SharedLocalizedString>>(
	translations: Translations,
) {
	const { TENANT_LOCALE } = getSharedGlobalSettings();
	return <Key extends keyof Translations>(
		key: Key,
		variables: Record<string, string | number> = {},
		disableNbspify = false,
	): Translations[Key][SharedLocale] => {
		return translate<Translations, Key>(translations, key, TENANT_LOCALE, variables, disableNbspify);
	};
}
