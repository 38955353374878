<script lang="ts">
	import Button from "../../../shared/components/Button.svelte";
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import { appName } from "../../../../core/schema/names/appName.js";
	import StaticPicture from "../../../shared/components/Picture/StaticPicture.svelte";
	import Drawer from "../../../shared/components/Drawer.svelte";
	import MobileMenu from "../../../shared/components/Menu/MobileMenu.svelte";
	import DesktopMenu from "../../../shared/components/Menu/DesktopMenu.svelte";
	import logo from "../../../../core/assets/logo-vertiflex-black.svg";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "../../../shared/components/Header.translations.json";
	import { getContext } from "svelte";
	import type { GlobalTemplateProps } from "../GlobalTemplateProps.js";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { showHeaderStore } from "../../../shared/stores/showHeaderStore.js";
	import LanguagePicker from "../../../shared/components/LanguagePicker.svelte";
	import { tenants } from "../../../../core/config/tenants.js";
	import { sharedLocaleShortHandMap } from "../../../../core/schema/names/sharedLocaleShortHandMap.js";

	export let menu: MenuItem[];

	const pageSlug = getContext<GlobalTemplateProps["pageSlug"]>(pageSlugContextKey);

	let isMenuOpen = false;

	const { TENANT_LOCALE, VERTIFLEX_WEB_URL } = getGlobalSettings();
	const translate = getTranslate(translations);

	function toggleMenu(): void {
		isMenuOpen = !isMenuOpen;
	}

	let scrollPosition = 0;
	let scrollFromLastDirectionChange = 0;
	const scrollTolerance = 25;
	let showHeader = true;

	function onScroll(event: UIEvent & { currentTarget: EventTarget & Window }): void {
		const { scrollY } = event.currentTarget;
		const newScrollPosition = Math.max(scrollY, 0);
		const oldDirection = scrollFromLastDirectionChange < 0 ? "up" : "down";
		const newDirection = newScrollPosition > scrollPosition ? "down" : "up";

		if (oldDirection !== newDirection) {
			scrollFromLastDirectionChange = 0;
		}
		scrollFromLastDirectionChange += newScrollPosition - scrollPosition;

		scrollPosition = newScrollPosition;

		if (Math.abs(scrollFromLastDirectionChange) > scrollTolerance) {
			showHeader = scrollFromLastDirectionChange < 0;
		}
	}
</script>

<svelte:window on:scroll={onScroll} />

{#if menu.length > 0}
	<Drawer bind:isOpen={isMenuOpen} position="left" dontShowCloseButton>
		<MobileMenu pageSlug={pageSlug[TENANT_LOCALE] ?? ""} bind:isOpen={isMenuOpen} {menu} />
	</Drawer>
{/if}

<!-- TODO - grayscale added to prevent flickering of header on page scroll in FireFox - https://stackoverflow.com/questions/30411909/css-transition-flickering-in-firefox -->
<header
	class="border-b-1 bg-gray/75 fixed left-0 right-0 top-0 z-30 h-20 w-full border-solid py-3 grayscale-[1%] backdrop-blur-md transition-[border,transform] duration-300 lg:h-auto lg:py-0"
	class:border-gray-200={scrollPosition > 0}
	class:border-transparent={scrollPosition <= 0}
	class:-translate-y-full={!showHeader && !$showHeaderStore}
	class:translate-y-0={showHeader || $showHeaderStore}
>
	<div class="container h-full">
		<div class="grid h-full w-full grid-cols-[1fr,auto,1fr] items-center gap-4 lg:gap-2 lg:px-8 lg:pt-1 xl:gap-12">
			<Button class="lg:hidden" variant="transparent" on:click={toggleMenu} aria-label={translate("menu", {}, true)}>
				<span class="text-xs font-semibold uppercase tracking-[0.15rem]">{translate("menu")}</span>
			</Button>
			<a href="/" class="h-auto w-32 lg:h-3 xl:h-auto xl:w-40">
				<StaticPicture
					alt="Logo {appName}"
					image={logo}
					imgClass="w-full h-auto lg:w-auto xl:w-full"
					loading="eager"
					width={181.61}
					height={20}
				/>
			</a>
			{#if menu.length > 0}
				<DesktopMenu pageSlug={pageSlug[TENANT_LOCALE] ?? ""} {menu} />
			{/if}
			<div class="flex justify-end">
				<LanguagePicker
					webUrl={VERTIFLEX_WEB_URL}
					currentLocale={TENANT_LOCALE}
					tenants={Object.values(tenants)}
					{pageSlug}
					localeShortHandMap={sharedLocaleShortHandMap}
				/>
			</div>
		</div>
	</div>
</header>
