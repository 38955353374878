import type { Locale } from "./Locale.js";

export enum TenantId {
	cz = "cz",
	eu = "eu",
	pl = "pl",
	sk = "sk",
}

export type TenantizedString = Record<TenantId, string>;

export interface Tenant<Id = TenantId> {
	readonly id: Id;
	readonly domain: string;
	readonly locale: Locale;
}
